import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { Router } from '@angular/router';


import { User } from '../../_models/user/user.model';
import { TokenService } from './token.service';
import { server } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public baseUrl = server.baseUrl;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private Token: TokenService,
    private http: HttpClient,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser        = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // 01 loginUser()
  loginUser(user: User) {
    return this.http.post<any>(`${this.baseUrl}/user/login`, user)
      .pipe(map(user => {
        if(user.data['isActive'] != 0){
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.Token.set(user.token);
          this.currentUserSubject.next(user);
          return user;
        }
        return false;        
      }));
  }

  // 02 logoutUser()
  logout() {
    this.Token.remove();
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }


  // 01 registerUser()
  registerUser(user: User) {
    return this.http.post<any>(`${this.baseUrl}/user/signup`, user)
      .pipe(map(user => {}));
  }

}
