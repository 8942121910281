export const environment = {
  production: true,
  Api: 'https://api.swapco.com.au/api/' //live 
  //Api: 'https://beta.swapco.com.au/api/' //Dev 


  //Api: 'http://ec2-18-222-40-127.us-east-2.compute.amazonaws.com:8000/api/' //live AWS
};
export const server = {
  baseUrl: "https://api.swapco.com.au/api/v1", //Live
  //baseUrl: "https://beta.swapco.com.au/api/v1", //Dev


  //baseUrl: "http://ec2-18-222-40-127.us-east-2.compute.amazonaws.com:8000/api/v1", //AWS
}