import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/guards';
import { Role } from "./_models";

const routes: Routes = [
   // membership for Mobile app
  // Front End
  {
    path: 'membership',
    loadChildren: () =>
      import('./modules/auth/membership/membership.module').then(
        mod => mod.MembershipModule
      )
  },


  // Dashboard
  // Auth
  {
    path: 'admin-login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        mod => mod.AuthModule
      )
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/user-login/user-login.module').then(
        mod => mod.UserLoginModule
      )
  },

  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        mod => mod.DashboardModule,
      ),
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin] }
  },

  // Front End
  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/auth/signup/signup.module').then(
        mod => mod.SignupModule
      )
  },


  // Home
  {
    path: '',
    loadChildren: () =>
      import('./modules/frontend/frontend.module').then(
        mod => mod.FrontendModule,
      ),
  },

  { path: '*', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
